<template>
  <div>
    <div class="flex justify-between mt-2">
      <div class="flex">
        <label
            :for="name"
            class="block text-sm font-medium text-gray-700 dark:text-gray-300"
        >
          {{ label }}
        </label>
        <span class="text-red-600 ml-0.5" v-if="required">*</span>
      </div>
      <div class="flex">
        <div class="text-sm text-gray-400 dark:text-gray-300">
          {{ formatPrice(minValue) }} - {{ formatPrice(maxValue) }}
        </div>
      </div>
    </div>
    <div class="mt-2">
      <div class="relative max-w-xl w-full">
        <div>
          <input type="range"
                 :step="step" v-model="minValue"
                 :min="min" :max="max"
                 class="absolute pointer-events-none appearance-none opacity-0 z-20 h-2 w-full cursor-pointer">

          <input type="range" v-model="maxValue"
                 :step="step"
                 :min="min" :max="max"
                 class="absolute pointer-events-none appearance-none z-20 h-2 w-full opacity-0 cursor-pointer">

          <div class="relative z-10 h-2">

            <div class="absolute z-10 left-0 right-0 bottom-0 top-0 rounded-md bg-gray-100 dark:bg-zinc-700">

            </div>

            <div class="absolute z-20 top-0 bottom-0 rounded-md bg-primary-500 dark:bg-primary-500"
                 :style="'right:'+maxThumb+'%; left:'+minThumb+'%'">

            </div>

            <div class="absolute z-30 w-6 h-6 top-0 left-0 bg-white border-gray-300 border dark:bg-zinc-600 dark:border-zinc-700 rounded-full -mt-2 -ml-1"
                 :style="'left: '+minThumb+'%'"></div>

            <div class="absolute z-30 w-6 h-6 top-0 right-0 bg-white border-gray-300 border dark:bg-zinc-600 dark:border-zinc-700  rounded-full -mt-2 -mr-3"
                 :style="'right: '+maxThumb+'%'"></div>
          </div>

        </div>
      </div>
    </div>
    <div class="flex justify-between mt-2">
      <button v-for="value in exampleValues" :key="value" @click="selectValue(value)" class="text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gra-400">
        {{ formatPrice(value) }}
      </button>
    </div>

    <p v-if="description" class="mt-2 text-sm text-gray-500">
      {{ description }}
    </p>
    <p v-if="error" class="mt-2 text-sm text-red-600 dark:text-red-500">
      {{ error }}
    </p>
  </div>
</template>

<script>
import {useErrorStore} from "~/stores/util/errors";
import {formatPrice} from "../../utils/formatPrice";

export default {
  name: "MultiRangeSlider",
  props: {
    modelValue: Object,
    name: String,
    label: String,
    description: String,
    required: Boolean,
    disabled: Boolean,
    min: Number,
    max: Number,
    step: Number,
  },
  data() {
    return {
      minInputValue: this.min,
      maxInputValue: this.max,
      minValue: this.min,
      maxValue: this.max,
      maxThumb: 0,
      minThumb: 0,
    }
  },
  mounted() {
    this.minTrigger()
    this.maxTrigger()
  },
  methods: {
    formatPrice,
    selectValue(value) {
      const diffToMin = Math.abs(value - this.minValue);
      const diffToMax = Math.abs(value - this.maxValue);

      // Update the variable that is closer to the value
      if (diffToMin < diffToMax) {
        this.minValue = value;
      } else {
        this.maxValue = value;
      }

    },
    updateValue() {
      this.$emit('update:modelValue', {
        min: this.minValue,
        max: this.maxValue,
      })
    },
    minTrigger() {
      this.minValue = Math.min(this.minValue, this.maxValue);
      this.minThumb = ((this.minValue - this.min) / (this.max - this.min)) * 100;
      this.updateValue()
    },
    maxTrigger() {
      this.maxValue = Math.max(this.maxValue, this.minValue);
      this.maxThumb = 100 - (((this.maxValue - this.min) / (this.max - this.min)) * 100);
      this.updateValue()

    },
  },
  watch: {
    minValue() {
      this.minTrigger()
      this.minInputValue = this.minValue
    },
    maxValue() {
      this.maxTrigger()
      this.maxInputValue = this.maxValue
    },
    minInputValue() {
      if (this.minValue < this.maxValue) {
        this.minValue = this.minInputValue
        this.minTrigger()
      }
    },
    maxInputValue() {
      if (this.maxValue > this.minValue) {
        this.maxValue = this.maxInputValue
        this.maxTrigger()
      }
    }
  },
  computed: {
    exampleValues() {
      return [
        this.min,
        this.min + ((this.max - this.min) / 4),
        this.min + ((this.max - this.min) / 2),
        this.min + ((this.max - this.min) / 4) * 3,
        this.max,
      ]
    },
    error() {
      const store = useErrorStore();
      return store.getFormError(this.name);
    },
  }
}
</script>

<style scoped>
input[type=range]::-webkit-slider-thumb {
  pointer-events: all;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* @apply w-6 h-6 appearance-none pointer-events-auto; */
}
</style>
