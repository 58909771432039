<script lang="ts">
import {defineComponent} from 'vue'
import {ChevronDownIcon} from "@heroicons/vue/20/solid";

export default defineComponent({
  name: "SortButton",
  components: {
    ChevronDownIcon
  },
  data() {
    return {
      selected: 'newest',
    }
  },
  watch: {
    selected(newValue) {
      this.$emit('update:modelValue', newValue)
    }
  },
  computed: {
    displayValue() {
      const selectedOption = this.options
          .flat() // Flatten the nested arrays
          .find(option => option.value === this.selected);

      return selectedOption ? selectedOption.label : 'sort';
    },
    options() {
      return [
        [
          {
            label: 'Price low to high',
            value: 'price_asc',
          },
          {
            label: 'Price high to low',
            value: 'price_desc',
          },
        ],
        [
          {
            label: 'Name A-Z',
            value: 'name_asc',
          },
          {
            label: 'Name Z-A',
            value: 'name_desc',
          },
        ],
          [
            {
              'label': 'Sales high to low',
              'value': 'sales_desc'
            },
            {
              'label': 'Sales low to high',
              'value': 'sales_asc'
            }
          ],
        [
          {
            label: 'Newest',
            value: 'newest',
          },
        ],
      ]
    }
  }
})
</script>

<template>
  <HeadlessMenu as="div" class="relative inline-block text-left">
    <div>

      <HeadlessMenuButton
          class="flex rounded-full bg-primary-500 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">
        {{ displayValue }}
        <ChevronDownIcon class="-mr-1 h-5 w-5 text-white" aria-hidden="true"/>
      </HeadlessMenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <HeadlessMenuItems
          class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-zinc-600 rounded-md bg-white dark:bg-zinc-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <template v-for="item in options" :key="item">
          <div class="py-1">
            <HeadlessMenuItem v-for="subItem in item" :key="subItem" v-slot="{ active }">
              <a @click="selected = subItem.value" :class="[active ?'bg-primary-600 text-white' : 'text-gray-900 dark:text-gray-100', 'block px-4 py-2 text-sm']">
                {{ subItem.label }}
              </a>
            </HeadlessMenuItem>
          </div>

        </template>


      </HeadlessMenuItems>
    </transition>
  </HeadlessMenu>
</template>

<style scoped>

</style>