<script>
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/vue/24/solid'

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  name: "Pagination",
  emits: ['changePage'],
  props: {
    data: Object
  },
  methods: {
    extractPageNumberFromUrl(inputUrl) {
      try {
        const url = new URL(inputUrl)
        return url.searchParams.get('page')
      } catch (e) {
        return null;
      }
    },
    changeToPage(number) {
      if (number < 1 || number > this.data.last_page || !number) {
        return
      }
      this.$emit('changePage', number)
    },
  },
  computed: {
    links() {
      if (!this.data || !this.data.links) {
        return [];
      }

      return this.data.links
    }
  }
}
</script>

<template>
  <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination"
       v-if="data && data.total > data.per_page">
    <div v-for="link in links" :key="link.label">
      <button v-if="link.label.includes('Previous') && link.url" @click="changeToPage(data.current_page - 1)"
              class="ml-2 dark:bg-zinc-900 relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium dark:border-zinc-700 text-gray-500 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-zinc-800">
        <span class="sr-only">Previous</span>
        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true"/>
      </button>

      <button v-else-if="link.label.includes('Next') && link.url" @click="changeToPage(data.current_page + 1)"
              class="dark:bg-zinc-900 relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium dark:border-zinc-700 text-gray-500 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-zinc-800">
        <span class="sr-only">Next</span>
        <ChevronRightIcon class="h-5 w-5" aria-hidden="true"/>
      </button>

      <button v-else-if="link.url || link.label.includes('...')" aria-current="page"
              @click="changeToPage(extractPageNumberFromUrl(link.url))"
              :class="[
      link.active ? 'z-10 bg-zinc-50 dark:bg-zinc-800 border-primary-700 text-primary-600' : 'bg-white dark:bg-zinc-900 hover:bg-gray-100 dark:hover:bg-zinc-800 border-gray-300 dark:border-zinc-700 text-gray-500 dark:text-gray-400',
      link.url ? 'hover:bg-gray-50' : 'cursor-not-allowed' // Conditionally add hover class
    ].join(' ')"
              class="relative inline-flex items-center px-4 py-2 border text-sm font-medium">
        {{ link.label }}
      </button>
    </div>
  </nav>
</template>

<style scoped>
.arrow-button {
  @apply bg-gray-200 hover:bg-gray-300 border border-gray-300 hover:border-gray-400 w-8 h-8 flex justify-center text-gray-500 hover:text-black dark:bg-gray-800
}

.number-button {
  @apply bg-white hover:bg-gray-300 border border-gray-300 hover:border-gray-400 w-8 h-8 flex justify-center text-zinc-600 hover:text-zinc-500 dark:bg-gray-700
}
</style>
