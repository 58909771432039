<script lang="ts">
import {defineComponent} from 'vue'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/vue/20/solid'
import {useErrorStore} from "~/stores/util/errors";

export default defineComponent({
  name: "Select",
  emits: ['update:modelValue'],
  components: {
    CheckIcon,
    ChevronUpDownIcon
  },
  props: {
    modelValue: Array,
    required: Boolean,
    name: String,
    label: String,
    records: Array,
    description: String,
    valueField: {
      type: String,
      default: 'id'
    },
    labelField: {
      type: String,
      default: 'label'
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selectedRecords: [],
      query: ''
    }
  },
  watch: {
    selectedRecords(newValue) {
      this.$emit('update:modelValue', newValue ? newValue.map(record => record[this.valueField]) : [])
    },
    // modelValue(newValue) {
    //   if (newValue === this.selectedRecords.map(record => record[this.valueField])) {
    //     return
    //   }
    //
    //   this.selectedRecords = this.records.filter(record => newValue.includes(record[this.valueField]))
    // }
  },
  methods: {
    removeRecord(record) {
      this.selectedRecords = this.selectedRecords.filter(selectedRecord => selectedRecord[this.valueField] !== record[this.valueField])
    }
  },
  computed: {
    filteredRecords() {
      return this.query === ''
          ? this.records
          : this.records.filter((record) => {
            return record[this.labelField].toLowerCase().includes(this.query.toLowerCase())
          })
      return []
    },
    error() {
      return useErrorStore().getFormError(this.name)
    },
  },
})
</script>

<template>
  <div>
    <HeadlessCombobox as="div" class="mt-2" v-model="selectedRecords" nullable multiple>
      <HeadlessComboboxLabel class="block text-sm font-medium text-gray-700 dark:text-gray-300 mt-1">{{
          label
        }}
      </HeadlessComboboxLabel>
      <div class="relative mt-1">
        <HeadlessComboboxInput @change="query = $event.target.value"

                               class="w-full rounded-md border-0 bg-white dark:bg-zinc-700 py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:ring-zinc-600 dark:text-gray-300 "/>
        <HeadlessComboboxButton
            class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
        </HeadlessComboboxButton>

        <HeadlessComboboxOptions v-if="filteredRecords.length > 0"
                                 class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-zinc-700 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <HeadlessComboboxOption v-for="record in filteredRecords" :key="record[valueField]" :value="record"
                                  as="template" v-slot="{ active, selected }">
            <li :class="['relative cursor-default select-none py-2 pl-8 pr-4', active ? 'bg-primary-600 text-white' : 'text-gray-900 dark:text-gray-100']">
            <span :class="['block truncate', selected && 'font-semibold']">
              {{ record[labelField] }}
            </span>

              <span v-if="selected"
                    :class="['absolute inset-y-0 left-0 flex items-center pl-1.5', active ? 'text-white' : 'text-primary-600']">
              <CheckIcon class="h-5 w-5" aria-hidden="true"/>
            </span>
            </li>
          </HeadlessComboboxOption>
        </HeadlessComboboxOptions>
      </div>
    </HeadlessCombobox>

    <p v-if="description" class="mt-2 text-sm text-gray-500" id="email-description">{{ description }}</p>
    <p v-if="error" class="mt-2 text-sm text-red-600" id="email-error">{{ error }}</p>
  </div>
  <ul class="flex flex-wrap gap-2 mt-2">
      <li  v-for="record in selectedRecords" :key="record[valueField]" class="inline-flex items-center gap-x-0.5 rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
        {{ record[labelField] }}
           <button type="button" @click="removeRecord(record)" class="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20">
      <span class="sr-only">Remove</span>
      <svg viewBox="0 0 14 14" class="h-3.5 w-3.5 stroke-red-600/50 group-hover:stroke-red-600/75">
        <path d="M4 4l6 6m0-6l-6 6"/>
      </svg>
      <span class="absolute -inset-1"/>
    </button>
      </li>

  </ul>
</template>

<style scoped>

</style>