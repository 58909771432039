<script lang="ts">
import {defineComponent} from 'vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import {useErrorStore} from "~/stores/util/errors";

export default defineComponent({
  name: "Select",
  emits: ['update:modelValue'],
  components: {
    CheckIcon,
    ChevronUpDownIcon
  },
  props: {
    modelValue: [String, Number],
    required: Boolean,
    name: String,
    label: String,
    records: Array,
    description: String,
    valueField: {
      type: String,
      default: 'id'
    },
    labelField: {
      type: String,
      default: 'label'
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selectedRecord: null,
    }
  },
  watch: {
    selectedRecord(newValue) {
      this.$emit('update:modelValue', newValue ? newValue[this.valueField] : null)
    },
    modelValue(newValue) {
      this.selectedRecord = this.records.find(record => record[this.valueField] === newValue)
    }
  },
  computed: {
    error() {
      return useErrorStore().getFormError(this.name)
    },
  },
})
</script>

<template>
  <div>
    <HeadlessListbox as="div" v-model="selectedRecord" :disabled="disabled">
      <div class="flex mt-1">
        <HeadlessListboxLabel class="block text-sm font-medium text-gray-700 dark:text-gray-300"> {{ label }}</HeadlessListboxLabel>
        <span class="text-red-600 ml-0.5" v-if="required">*</span>
      </div>

      <div class="relative mt-1">
        <HeadlessListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-zinc-700 dark:ring-zinc-600 dark:text-gray-400 ">
        <span class="block truncate">{{
            selectedRecord ? selectedRecord[labelField] : 'Select an option'

          }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
        </HeadlessListboxButton>

        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <HeadlessListboxOptions class="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm  dark:bg-zinc-700 dark:ring-zinc-600">
            <HeadlessListboxOption as="template" v-for="record in records" :key="record[labelField]" :value="record" v-slot="{ active, selected }">
              <li :class="[active ? 'bg-primary-600 text-white' : 'text-gray-900 dark:text-gray-400', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ record[labelField] }}</span>

                <span v-if="selected" :class="[active ? 'text-white' : 'text-primary-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
              </li>
            </HeadlessListboxOption>
          </HeadlessListboxOptions>
        </transition>
      </div>
    </HeadlessListbox>
    <p v-if="description" class="mt-2 text-sm text-gray-500" id="email-description">{{ description }}</p>
    <p v-if="error" class="mt-2 text-sm text-red-600" id="email-error">{{ error }}</p>
  </div>
</template>

<style scoped>

</style>